import supportsService from "../services/supports.service";
import { parseFormError } from "../services/utils";

const initialState = {
  supports: [],
  loading: false,
  page: 1,
  lastPage: 1,
  perPage: 10,
  total: 0,
  query: "",
  form: {
    open: false,
    support: null,
    error: null,
  },
};

export const supports = {
  namespaced: true,
  state: initialState,
  actions: {
    async getSupports({ commit, state }) {
      commit("SET_LOADING", true);
      const response = await supportsService.getSupports(state.page, state.perPage);
      commit("SET_SUPPORTS", response.data);
      commit("SET_PAGE", response.current_page);
      commit("SET_TOTAL", response.total);
      commit("SET_PER_PAGE", response.per_page);
      commit("SET_LAST_PAGE", response.last_page);
    },

    async findSupports({ commit, state }, query) {
      commit("SET_QUERY", query);
      commit("SET_LOADING", true);
      const response = await supportsService.findSupports(query, state.page, state.perPage);
      commit("SET_SUPPORTS", response.data);
      commit("SET_PAGE", response.current_page);
      commit("SET_TOTAL", response.total);
      commit("SET_PER_PAGE", response.per_page);
      commit("SET_LAST_PAGE", response.last_page);
    },

    startAdding({ commit }) {
      const value = { open: true, support: null };
      commit("SET_FORM", value);
    },

    async addSupport({ commit, dispatch }, support) {
      try {
        await supportsService.addSupports(support);
        dispatch("stopEditing");
        dispatch("getSupports");
      } catch (error) {
        commit("SET_FORM_ERROR", parseFormError(error));
      }
    },

    async startEditing({ commit }, support) {
      const value = { open: true, supports: support }; 
      commit("SET_FORM", value);
    },    

    async updateSupport({ commit, dispatch }, support) {
      try {
        await supportsService.updateSupports(support);
        dispatch("stopEditing");
        dispatch("getSupports");
      } catch (error) {
        commit("SET_FORM_ERROR", parseFormError(error));
      }
    },

    async stopEditing({ commit }) {
      const value = { open: false, support: null };
      commit("SET_FORM", value);
    },

    async deleteSupport({ dispatch }, id) {
      try {
        await supportsService.deleteSupports(id);
        dispatch("getSupports");
      } catch (error) {
        console.error("Erro ao deletar atendimento:", error);
      }
    },

    changeToPage({ commit, dispatch, state }, page) {
      if (page >= 1 && page <= state.lastPage) {
        commit("SET_PAGE", page);
        dispatch("getSupports");
      }
    },

    changePerPage({ commit, dispatch }, perPage) {
      commit("SET_PER_PAGE", perPage);
      dispatch("getSupports");
    },
  },
  mutations: {
    SET_SUPPORTS(state, supports) {
      state.supports = supports;
      state.loading = false;
    },

    SET_LOADING(state, value) {
      state.loading = value;
    },

    SET_QUERY(state, query) {
      state.query = query;
    },

    SET_PAGE(state, page) {
      state.page = page;
    },

    SET_TOTAL(state, total) {
      state.total = total;
    },

    SET_PER_PAGE(state, per_page) {
      state.perPage = per_page;
    },

    SET_LAST_PAGE(state, last_page) {
      state.lastPage = last_page;
    },

    SET_FORM(state, { open, supports }) {
      state.form.open = open;
      state.form.supports = supports || null;
    },    

    SET_FORM_ERROR(state, error) {
      state.form.error = error;
    },

    resetState(state) {
      state.supports = [];
      state.loading = false;
      state.form = {
        open: false,
        support: null,
      };
    },
  },
  getters: {
    supports(state) {
      return state.supports;
    },

    totalSupports(state) {
      return state.total;
    },

    perPage(state) {
      return state.perPage;
    },

    loading(state) {
      return state.loading;
    },

    form(state) {
      return state.form;
    },
  },
};
