import api from './axios'; 

export default {
  async getSupports(page = 1) {
    const response = await api.get(`/supports?page=${page}`);
    return response.data;
  },

  async getAllSupports() {
    const response = await api.get(`/supports?all=true`);
    return response.data;
  },

  async addSupports(supports) {
    const response = await api.post('/supports', supports);
    return response.data;
  },

  async updateSupports(supports) {
    const response = await api.put(`/supports/${supports.id}`, supports);
    return response.data;
  },

  async deleteSupports(id) {
    const response = await api.delete(`/supports/${id}`);
    return response.status === 204; 
  }
}
