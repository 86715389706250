<template>
  <div
    class="tab-pane fade show active"
    id="data"
    role="tabpanel"
    aria-labelledby="data-tab"
  >
    <div class="row">
      <div class="col-12 mb-3">
        <label class="form-label">Data de Criação</label>
        <input
          type="text"
          class="form-control"
          :value="formatDate(modelValue.created_at)"
          disabled
        />
      </div>
    </div>

    <label for="name">Nome da Empresa<span class="required">*</span></label>
      <soft-field
        id="company_name"
        v-model="lead.company_name"
        type="text"
        placeholder="Empresa"
        name="company_name"
    /> 

    <label for="name">Nome para Contato<span class="required">*</span></label>
      <soft-field
          id="name"
          v-model="lead.name"
          type="text"
          placeholder="Nome"
          name="name"
      /> 
    <label for="email">Email<span class="required">*</span></label>
      <soft-field
          id="email"
          v-model="lead.email"
          type="email"
          placeholder="Email"
          name="email"
      />
    <label for="phone">Telefone</label>
      <soft-field
          id="phone"
          v-model="lead.phone"
          type="text"
          placeholder="Telefone"
          name="phone"
      />
    <label for="state">Estado</label>
      <soft-field
          id="state"
          v-model="lead.state"
          type="text"
          placeholder="Estado"
          name="state"
      />
    <label for="city">Cidade</label>
      <soft-field
          id="city"
          v-model="lead.city"
          type="text"
          placeholder="Cidade"
          name="city"
      />
    <label for="zone">Região</label>
      <soft-field
          id="zone"
          v-model="lead.zone"
          type="text"
          placeholder="Região"
          name="zone"
      />
    <!-- <label for="company_id">Empresa</label>
      <soft-select-search
          v-if="companies && companies.length > 0"
          id="company_id"
          v-model="lead.company_id"
          placeholder="Empresa"
          name="company_id"
          :options="companies"
      /> -->

    <label for="source">Origem</label>
      <select
          id="source"
          v-model="lead.source"
          name="source"
          class="form-select"
      >
          <option value="" disabled>Selecione a origem</option>
          <option value="Instagram">Instagram</option>
          <option value="Site">Site</option>
          <option value="Prospecção">Prospecção</option>
          <option value="Indicação">Indicação</option>
          <option value="Google">Google</option>
      </select>
    <label for="funnel_stage">Fase do Funil</label>
      <select
          id="funnel_stage"
          v-model="lead.funnel_stage"
          name="funnel_stage"
          class="form-select"
      >
          <option value="" disabled>Selecione a fase do funil</option>
          <option value="Oportunidade">Oportunidade</option>
          <option value="Contato 1">Contato 1</option>
          <option value="Contato 2">Contato 2</option>
          <option value="Contato 3">Contato 3</option>
          <option value="Expectativa 1º atendimento">Expectativa 1º atendimento</option>
          <option value="Reunião">Reunião</option>
          <option value="Retorno Reunião">Retorno Reunião</option>
          <option value="Fechamento">Fechamento</option>
          <option value="Agendamento exame">Agendamento exame</option>
      </select>
    <!-- <label for="assigned_user_id">Usuário Responsável</label>
      <soft-select-search
          id="assigned_user_id"
          v-model="lead.assigned_user_id"
          placeholder="Usuário Responsável"
          name="assigned_user_id"
          :options="users"
      /> -->
    <label for="notes">Notas</label>
      <soft-field
          id="notes"
          v-model="lead.notes"
          type="text"
          placeholder="Notas"
          name="notes"
      /> 
  </div>
</template>
  
<script>
  // import SoftSwitchModel from "../../components/SoftSwitchModel.vue";
  // import SoftSelect from "../../components/SoftSelect";
  import "vue-search-select/dist/VueSearchSelect.css"
  import SoftField from "../../components/SoftField.vue";
  // import SoftSelectSearch from "../../components/SoftSelectSearch.vue";

  export default {
    name: "LeadDataTab",
    props: ['modelValue'],
    components: {
      SoftField,
      // SoftSelectSearch,
    },
    data() {
    },
    computed: {
      lead: {
        get() {
          return this.modelValue
        },
        set(newValue) {
          this.$emit('update:modelValue', newValue)
        },
      },
    },
    methods: {
      formatDate(date) {
        if (!date) return '';
        return new Date(date).toLocaleString('pt-BR');
      },
    },
  };
</script>
