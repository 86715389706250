<template>
  <div class="tab-pane fade" id="activity" role="tabpanel" aria-labelledby="activity-tab">
      <div class="container mt-3">
          <h4 class="mb-2">Gerenciar Atividades</h4>
          <hr>
          <transition-group name="fade" tag="div">
              <div 
                  v-for="(activity, index) in activities"
                  :key="index" 
                  class="border rounded p-3 mb-3 shadow-sm"
              >
                  <div class="mb-3">
                      <label class="form-label">
                          Atividade Relacionada: <span class="text-danger">*</span>
                      </label>
                      <select
                          v-model="activity.activity"
                          class="form-select select-custom"
                      >
                          <option value="" disabled>Selecione a atividade</option>
                          <option value="call">Ligação</option>
                          <option value="email">E-Mail</option>
                          <option value="meeting">Reunião</option>
                          <option value="visit">Visita</option>
                          <option value="whatsapp">Mensagem WhatsApp</option>
                          <option value="another">Outro</option>
                      </select>
                  </div>

                  <div class="mb-3">
                      <label class="form-label">
                          Status: <span class="text-danger">*</span>
                      </label>
                      <select
                          v-model="activity.status"
                          class="form-select select-custom"
                      >
                          <option value="open">Em aberto</option>
                          <option value="in_progress">Em andamento</option>
                          <option value="completed">Concluído</option>
                      </select>
                  </div>

                  <div class="mb-3">
                      <label class="form-label">
                          Data: <span class="text-danger">*</span>
                      </label>
                      <input
                          type="date"
                          v-model="activity.date"
                          class="form-control"
                      />
                  </div>

                  <div class="mb-3">
                      <label class="form-label">
                          Anotações
                      </label>
                      <textarea
                          v-model="activity.notes"
                          class="form-control"
                          rows="6"
                          placeholder="Insira suas anotações aqui..."
                          :maxlength="500"
                      ></textarea>
                      <small class="form-text text-muted">
                          Caracteres restantes: {{ 500 - activity.notes.length }}
                      </small>
                  </div>

                  <div class="d-flex justify-content-end">
                      <button type="button" class="btn btn-dark" @click="saveActivity(index)">
                          <span v-if="loading" class="spinner-border spinner-border-sm"></span>
                          <span v-else>Salvar Atividades</span>
                      </button>
                  </div>
              </div>
          </transition-group>
      </div>
  </div>
</template>
  
<script>
import showSwal from "../../mixins/showSwal"; 
import * as yup from 'yup';

export default {
  name: "LeadActivityTab",
  props: ['modelValue', 'leadId'],
  data() {
    const schema = yup.object().shape({
      activity: yup.string().required("A atividade é obrigatória."),
      status: yup.string().required("O status é obrigatório."),
      date: yup.date().required("A data é obrigatória."),
      notes: yup.string().nullable(),
    });

    return {
      loading: false,
      activities: [
        { lead_id:
          this.leadId,
          activity: '',
          status: 'open',
          date: '',
          notes: ''
        },
      ],
      schema,
    };
  },
  methods: {
    resetActivityFields(activity) {
        activity.activity = '';
        activity.status = 'open';
        activity.date = '';
        activity.notes = '';
    },

    async saveActivity(index) {
      const activity = this.activities[index];
      activity.lead_id = this.leadId;
      activity.loading = true;

      try {
        await this.schema.validate(activity, { abortEarly: false });

        await this.$store.dispatch("leadActivities/addLeadActivity", activity);
        showSwal.methods.showSwal({
          type: 'success',
          message: 'Atividade adicionada com sucesso!',
          time: 5000,
        });
        this.resetActivityFields(activity);
      } catch (error) {
        console.error("Erro no salvamento:", error); 
        if (error instanceof yup.ValidationError) {
          const messages = error.errors.join("\n");
          showSwal.methods.showSwal({
            title: 'Erro de validação',
            message: messages,
            time: 5000,
          });
        } else {
          showSwal.methods.showSwal({
            type: 'error',
            message: 'Ocorreu um erro ao salvar a atividade',
            time: 5000,
          });
        }
      } finally {
        activity.loading = false;
      }
    },
  },
};
</script>
