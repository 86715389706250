<template>
  <ErrorMessage
    :name="name"
    class="error-feedback text-xs text-danger"
    role="alert"
  />
  <div 
    :id="id"
    class="file-box"
    :class="{'bg-success': !preview}"
    @click="chooseFile()"
    @mouseover="onMouseOver"
    @mouseleave="onMouseLeave"
    :style="{ 
      width: `${width}px`, 
      height: `${height}px`
    }"
  >
    <Field :name="name" v-model="value">
      <template v-if="preview">
        <img v-if="isImage" :src="preview" class="preview-content" />
        <div v-else class="preview-content file-icon">
          <i class="fas fa-file fa-3x"></i>
          <span class="filename">{{ fileName }}</span>
        </div>
        <div class="actions-overlay" v-show="showActions">
          <button class="action-btn download" @click.stop="downloadFile">
            <i class="fas fa-download"></i>
          </button>
        </div>
      </template>
      <template v-else>
        <div class="preview-content default-icon">
          <i class="fas fa-upload fa-3x"></i>
          <span class="filename">Nenhum arquivo selecionado</span>
        </div>
      </template>
      <input 
        hidden 
        ref="fileUpload" 
        type="file" 
        :accept="accept"
        @change="onChange"
      >
    </Field>
  </div>
</template>

<script>
import { ErrorMessage, Field } from 'vee-validate';

export default {
  name: "SoftFileUpload",
  components: {
    Field,
    ErrorMessage
  },
  props: {
    width: {
      type: Number,
      default: 300,
    },
    height: {
      type: Number,
      default: 300,
    },
    name: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    modelValue: {
      type: [String, File],
      default: null
    },
    initialValue: {
      type: String,
      default: "",
    },
    accept: {
      type: String,
      default: '.jpg,.jpeg,.png,.pdf,.docx'
    }
  },
  data() {
    return {
      preview: null,
      fileName: '',
      isImage: false,
      showMessage: true,
      showActions: false,
      fileUrl: null
    }
  },
  watch: {
    initialValue: {
      immediate: true,
      handler(newInit) {
        this.clearState();
        if (newInit) {
          this.preview = newInit;
          this.isImage = this.checkIfImage(newInit);
          this.showMessage = false;

          try {
            const url = new URL(newInit);
            const pathSegments = url.pathname.split('/');
            const fullFileName = pathSegments[pathSegments.length - 1];
            this.fileName = fullFileName.split('?')[0];
          } catch (e) {
            this.fileName = 'Arquivo';
          }
        }
      }
    },
    modelValue: {
      immediate: true,
      handler(newValue) {
        if (!newValue) {
          this.clearState();
        } else if (typeof newValue === 'string' && newValue.startsWith('http')) {
          this.preview = newValue;
          this.isImage = this.checkIfImage(newValue);
          this.showMessage = false;
        }
      }
    }
  },
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    }
  },
  methods: {
    clearState() {
      this.preview = null;
      this.fileName = '';
      this.isImage = false;
      this.showMessage = true;
      if (this.$refs.fileUpload) {
        this.$refs.fileUpload.value = null;
      }
    },
    chooseFile() {
      this.$refs.fileUpload.click();
    },
    onChange(e) {
      const file = e.target.files[0];
      if (!file) {
        return;
      }

      this.fileName = file.name;
      this.isImage = file.type.startsWith('image/');
      this.value = file;

      if (this.isImage) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = e => {
          this.preview = e.target.result;
        };
      } else {
        this.preview = true;
      }
      this.showMessage = false;
    },
    onMouseOver() {
      this.showMessage = true;
      this.showActions = true;
    },
    onMouseLeave() {
      if (this.preview) {
        this.showMessage = false;
        this.showActions = false;
      }
    },
    async downloadFile(e) {
      e.preventDefault();
      if (!this.preview) return;
      
      try {
        const response = await fetch(this.preview);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = this.fileName;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      } catch (error) {
        console.error('Erro ao baixar arquivo:', error);
      }
    },
    checkIfImage(url) {
      if (!url) return false;
      try {
        const urlObj = new URL(url);
        const pathSegments = urlObj.pathname.split('/');
        const fileName = pathSegments[pathSegments.length - 1].split('?')[0].toLowerCase();
        return /\.(jpg|jpeg|png|)$/i.test(fileName);
      } catch (e) {
        return false;
      }
    }
  },
  beforeUnmount() {
    this.clearState();
  }
}
</script>

<style scoped>
.file-box {
  display: flex;
  position: relative;
  cursor: pointer;
  border: 2px dashed #ddd;
  border-radius: 8px;
  overflow: hidden;
}

.preview-content {
  object-fit: cover;
  width: 100%;
  height: 100%;
  transition: filter 0.3s ease;
}

.file-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #f8f9fa;
  gap: 1rem;
}

.filename {
  font-size: 0.875rem;
  text-align: center;
  word-break: break-word;
  padding: 0 1rem;
}

.msg {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  padding: 2em;
  text-align: center;
  background-color: rgba(0, 0, 0, .5);
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.default-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #f8f9fa;
  gap: 1rem;
  color: #adb5bd;
}

.actions-overlay {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  gap: 1rem;
  z-index: 2;
}

.action-btn {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  color: white;
}

.action-btn:hover {
  transform: scale(1.1);
}

.download {
  background-color: #5bccbf;
}


.actions-overlay:hover + .preview-content,
.actions-overlay:hover ~ .preview-content {
  filter: brightness(0.7);
}
</style>
