import api from "./axios";
import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.VUE_APP_API_BASE_URL;

export default {
    async getEquipaments(page = 1, per_page = 10) {
        const response = await api.get(`/equipaments?page=${page}&per_page=${per_page}`);
        return response.data;
    },
    async addEquipaments(equipaments) {
        const formData = new FormData();
        for (const key in equipaments) {
            formData.append(key, equipaments[key]);
        }
        const response = await api.post('/equipaments', formData, {
            headers: { "Content-Type": "multipart/form-data" }
        })

        return response.data;
    },

    async exportAllEquipaments() {
        const response = await api.get('equipaments/exportAllEquipaments', { responseType: 'blob' });
        return response.data; 
    },

    async updateEquipaments(equipaments) {
        const formData = new FormData();
        for (const key in equipaments) {
            if (equipaments[key]) formData.append(key, equipaments[key]);
        }

        const url = `${API_URL}/equipaments/${equipaments.id}`;

        const response = await axios.post(url, formData, {
            headers: authHeader()
        });

        return response.data;
    },

    async findEquipaments(query, page= 1, per_page = 10) {
        const response = await api.get(`/equipaments`, {
            params: {
                query: query,
                page: page,
                per_page: per_page
            }
        });
        return response.data;
    },
}