export const PersonalHistories = {
    HYPERTENSION: "Hipertensão Arterial Sistêmica",
    DIABETES: "Diabetes Melitus",
    ARTHRITIS: "Artrite Reumatóide",
    THYROID_DISEASE: "Doenças Tireoidianas",
    PREGNANCY: "Gestação",
    BREASTFEEDING: "Amamentação",
    OTHERS: "Outros",
};

export default PersonalHistories;

export const PersonalHistoriesOptions = Object.values(PersonalHistories).map(value => ({
    value: value,
    label: value,
}));
