export const EyeDiseasesPrevious = {
    GLAUCOMA: "Glaucoma",
    CERATOCONE: "Ceratocone",
    DMRI: "DMRI (Degeneração Macular Relacionada à Idade)",
    TRAUMA_OCULAR: "Trauma Ocular",
    OTHERS: "Outros",
};

export default EyeDiseasesPrevious;

export const EyeDiseasesPreviousOptions = Object.values(EyeDiseasesPrevious).map(value => ({
    value: value,
    label: value,
}));
