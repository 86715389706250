<template>
  <div class="tab-pane fade" id="history-activity" role="tabpanel" aria-labelledby="history-activity-tab">
    <h4 class="mb-2">Histórico de Atividades</h4>
    <hr class="mt-2">

    <table class="activity-table">
      <thead>
        <tr>
          <th>Status</th>
          <th>Atividade</th>
          <th>Data</th>
          <th class="action-column">Editar</th>
          <th class="action-column">Excluir</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="activity in leadActivities" :key="activity.id">
          <td>{{ getStatusLabel(activity.status) }}</td>
          <td>{{ getActivityLabel(activity.activity) }}</td>
          <td>{{ formatDate(activity.date) }}</td>
          <td class="action-column">
            <button class="btn-table" @click="editActivity(activity)" type="button">
              <i class="fas fa-edit"></i>
            </button>
          </td>
          <td class="action-column">
            <button class="btn-table" @click.prevent="deleteActivity(activity.id)" type="button">
              <i class="fas fa-trash"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <div v-if="showEditModal" class="modal fade show" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Editar Atividade</h5>
          </div>
          <div class="modal-body">
            <form @submit.prevent="saveActivity">
              <div class="mb-3">
                <label for="activitySelect" class="form-label">Atividade</label>
                <select v-model="editActivityData.activity" id="activitySelect" class="form-select">
                  <option value="call">Ligação</option>
                  <option value="email">E-Mail</option>
                  <option value="meeting">Reunião</option>
                  <option value="visit">Visita</option>
                  <option value="whatsapp">Mensagem WhatsApp</option>
                  <option value="another">Outro</option>
                </select>
              </div>

              <div class="mb-3">
                <label for="statusSelect" class="form-label">Status</label>
                <select v-model="editActivityData.status" id="statusSelect" class="form-select">
                  <option value="open">Em aberto</option>
                  <option value="in_progress">Em andamento</option>
                  <option value="completed">Concluído</option>
                </select>
              </div>

              <div class="mb-3">
                <label for="dateInput" class="form-label">Data</label>
                <input v-model="editActivityData.date" type="date" id="dateInput" class="form-control" />
              </div>

              <div class="mb-3">
                <label for="notesTextarea" class="form-label">Anotações</label>
                <textarea v-model="editActivityData.notes" id="notesTextarea" class="form-control" rows="6"></textarea>
                <small class="form-text text-muted">
                  Caracteres restantes: {{ 500 - (editActivityData.notes ? editActivityData.notes.length : 0) }}
                </small>
              </div>

              <div class="d-flex justify-content-between">
                <button type="button" class="btn btn-secondary" @click="closeEditModal" aria-label="Close">Cancelar</button>
                <button type="submit" class="btn btn-dark">Salvar</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import showSwal from "../../mixins/showSwal";
import { format, parseISO } from "date-fns";
import Swal from 'sweetalert2';


export default {
  name: "LeadHistoryActivityTab",
  props: ["modelValue", "leadId"],

  data() {
    return {
      showEditModal: false,
      editActivityData: {
        activity: "",
        status: "",
        date: "",
        notes: "",
      },
    };
  },

  computed: {
    ...mapState("leadActivities", ["leadActivities"]),
  },

  watch: {
    leadId(newLeadId) {
      this.fetchActivities(newLeadId);
    },
  },

  methods: {
    async fetchActivities(leadId) {
      try {
        await this.$store.dispatch("leadActivities/getLeadActivitiesByLeadId", leadId);
      } catch (error) {
        console.error("Erro ao buscar as atividades do lead:", error);
      }
    },

    async deleteActivity(activityId) {
  const result = await Swal.fire({
    title: "Confirmação",
    text: "Você tem certeza que deseja excluir esse histórico?",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#d33",
    cancelButtonColor: "#3085d6",
    confirmButtonText: "Sim, excluir!",
    cancelButtonText: "Cancelar",
  });

  if (result.isConfirmed) {
    try {
      const data = {
        activityId: activityId,
        leadId: this.leadId,
      };
      await this.$store.dispatch("leadActivities/deleteLeadActivity", data);
      Swal.fire({
        icon: "success",
        title: "Sucesso",
        text: "Atividade excluída com sucesso!",
        timer: 5000,
      });
    } catch (error) {
      console.error("Erro ao excluir a atividade:", error);
      Swal.fire({
        icon: "error",
        title: "Erro",
        text: "Erro ao excluir a atividade",
        timer: 5000,
      });
    }
  }
},

    editActivity(activity) {
      this.editActivityData = { ...activity };
      this.showEditModal = true;
    },

    closeEditModal() {
      this.showEditModal = false;
    },

    async saveActivity() {
      try {
        await this.$store.dispatch("leadActivities/updateLeadActivity", this.editActivityData);
        showSwal.methods.showSwal({
          type: "success",
          message: "Atividade atualizada com sucesso!",
          timer: 5000,
        });
        this.closeEditModal();
      } catch (error) {
        console.error("Erro ao salvar a atividade:", error);
        showSwal.methods.showSwal({
          type: "error",
          message: "Erro ao salvar a atividade",
          timer: 5000,
        });
      }
    },

    getStatusLabel(status) {
      const statusMap = {
        open: "Em aberto",
        in_progress: "Em andamento",
        completed: "Concluído",
      };
      return statusMap[status] || status;
    },

    getActivityLabel(activity) {
      const activityMap = {
        call: "Ligação",
        email: "E-mail",
        meeting: "Reunião",
        visit: "Visita",
        whatsapp: "WhatsApp",
        another: "Outro",
      };
      return activityMap[activity] || activity;
    },

    formatDate(date) {
      const parsedDate = parseISO(date);
      return format(parsedDate, "dd/MM/yyyy");
    },
  },

  mounted() {
    this.fetchActivities(this.leadId);
  },
};
</script>

<style scoped>
.modal.fade.show {
  display: block;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  width: 80%;
  margin: auto;
  padding: 20px;
}

.activity-table {
  width: 100%;
  max-width: 100%;
  border-collapse: collapse;
  margin: 0 auto;
  table-layout: fixed;
  table-layout: auto;
  word-wrap: break-word;
  overflow-x: auto;
}

.activity-table th,
.activity-table td {
  border: 1px solid #ddd;
  padding: 4px;
  text-align: center;
  font-size: 0.75rem;
  word-wrap: break-word;
}

.activity-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.activity-table td.action-column {
  width: auto; 
  white-space: nowrap;
}

.btn-table {
  padding: 2px 6px; 
  font-size: 0.75rem;
  border-radius: 3px;
  margin-right: 6px;
  cursor: pointer;
  background-color: transparent;
  border: none;
}

button:last-child {
  margin-right: 0;
}

.btn-table:hover {
  background-color: #f2f2f2;
}

.fas {
  font-size: 1rem; 
  color: #333;
}

button:hover .fas {
  color: darkblue;
}
</style>
