export const EyeSurgeryPrevious = {
    CATARACT_SURGERY: "Cirurgia de Catarata",
    REFRACTIVE_SURGERY: "Cirurgia Refrativa (LASIK/PRK)",
    VITRECTOMY: "Vitrectomia",
    CORNEAL_TRANSPLANT: "Transplante de Córnea",
    GLAUCOMA_SURGERY: "Cirurgia de Glaucoma",
    OTHERS: "Outros",
};

export default EyeSurgeryPrevious;

export const EyeSurgeryPreviousOptions = Object.values(EyeSurgeryPrevious).map(value => ({
    value: value,
    label: value,
}));
