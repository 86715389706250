<template>
  <div
    class="tab-pane fade show active"
    id="data"
    role="tabpanel"
    aria-labelledby="data-tab"
  >
    <label for="equipament_type">Tipo de Equipamento<span class="required">*</span></label>
    <soft-select-search
      id="equipament_type"
      name="equipament_type"
      v-model="state.equipament_type"
      :options="EquipamentsTypesOptions"
      placeholder="Selecione o tipo de equipamento"
    />

    <label>Marca do Equipamento</label>
    <soft-field
      id="mark"
      v-model="state.mark"
      type="text"
      placeholder="Qual a marca do equipamento?"
      name="mark"
    />

    <label>Modelo</label>
    <soft-field
      id="model"
      v-model="state.model"
      type="text"
      placeholder="Qual o modelo do seu equipamento?"
      name="model"
    />

    <label>Número de Série</label>
    <soft-field
      id="serial_number"
      v-model="state.serial_number"
      type="text"
      placeholder="Qual o número de série do seu equipamento?"
      name="serial_number"
    />

    <label>Número de Ativo</label>
    <soft-field
      id="active_number"
      v-model="state.active_number"
      type="text"
      placeholder="Qual número de ativo do dispositivo"
      name="active_number"
    />

    <div class="d-flex flex-column align-items-center gap-5 mt-5">
      <div class="d-flex flex-column align-items-center gap-2">
        <h4>Nota Fiscal</h4>
        <soft-file-upload
          id="invoice_img"
          name="invoice_img"
          :initialValue="state.invoice"
          v-model="state.invoice_img"
        />
      </div>
      
      <div class="d-flex flex-column align-items-center gap-2">
        <h4>Seguro</h4>
        <soft-file-upload
          id="insurance_img"
          name="insurance_img"
          :initialValue="state.insurance"
          v-model="state.insurance_img"
        />
      </div>
    </div>

    <label for="obs">Observações</label>
    <soft-field
      class="custom-label"
      id="obs"
      v-model="state.obs"
      type="text"
      placeholder="Observações Gerais"
      name="obs"
    />
  </div>
</template>

<script>
import "vue-search-select/dist/VueSearchSelect.css";
import formModalMixin from "../../mixins/form-modal-mixin";
import SoftField from "../../components/SoftField.vue";
import { EquipamentsTypesOptions } from "../../constants/EquipamentsTypes";
import SoftSelectSearch from "../../components/SoftSelectSearch.vue";
import SoftFileUpload from "../../components/SoftFileUpload.vue";

export default {
  name: "EquipamentsDataTab",
  props: ["modelValue"],
  mixins: [formModalMixin('equipaments')],
  components: {
    SoftField,
    SoftSelectSearch,
    SoftFileUpload
  },
  data() {
    return {
      EquipamentsTypesOptions,
    };
  },
  computed: {
    state: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue);
      },
    },
  },
};
</script>
