import equipamentsService from "../services/equipaments.service";
import { parseFormError } from "../services/utils";

const initialState = {
  equipaments: [],
  loading: false,
  page: 1,
  lastPage: 1,
  perPage: 10,
  total: 0,
  query: "", 
  form: {
    open: false,
    equipament: null,
    error: null
  }
}

export const equipaments = {
  namespaced: true,
  state: initialState,
  actions: {
    async getEquipaments({ commit, state }) {
      commit("SET_LOADING", true);
      const response = await equipamentsService.getEquipaments(state.page, state.perPage);
      commit("SET_EQUIPAMENTS", response.data);
      commit("SET_PAGE", response.current_page);
      commit("SET_TOTAL", response.total);
      commit("SET_PER_PAGE", response.per_page);
      commit("SET_LAST_PAGE", response.last_page);
    },
    async findEquipaments({ commit, state }, query) {
      commit("SET_QUERY", query);
      commit("SET_LOADING", true);
      const response = await equipamentsService.findEquipaments(query, state.page, state.perPage);
      commit("SET_EQUIPAMENTS", response.data);
      commit("SET_PAGE", response.current_page);
      commit("SET_TOTAL", response.total);
      commit("SET_PER_PAGE", response.per_page);
      commit("SET_LAST_PAGE", response.last_page);
    },
    changeToPage({ commit, dispatch, state }, page) {
      if (page >= 1 && page <= state.lastPage) {
        commit("SET_PAGE", page);
        dispatch("getEquipaments");
      }
    },

    changePerPage ({commit, dispatch}, perPage){
      commit("SET_PER_PAGE", perPage);
      dispatch("getEquipaments");
    },


    startAdding({ commit }) {
      commit("SET_FORM", { open: true, equipaments: null });
    },
    async addEquipaments({ commit, dispatch }, equipaments) { 
      try {
        await equipamentsService.addEquipaments(equipaments);
        dispatch("stopEditing");
        dispatch("getEquipaments");
      } catch (error) {
        commit('SET_FORM_ERROR', parseFormError(error));
      }
    },
    async exportAllEquipaments({ commit }) {
      commit("SET_LOADING", true);

      try {
        const response = await equipamentsService.exportAllEquipaments();

        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'equipamentos.xlsx');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        commit("SET_LOADING", false);
      } catch (error) {
        commit("SET_LOADING", false);
        console.error("Erro ao baixar o arquivo:", error);
      }
    },
    async startEditing({ commit }, equipaments) {
      commit("SET_FORM", { open: true, equipaments });
    },
    async updateEquipaments({ commit, dispatch }, equipaments) {
      try {
        await equipamentsService.updateEquipaments(equipaments);
        dispatch("stopEditing");
        dispatch("getEquipaments");
      } catch (error) {
        commit('SET_FORM_ERROR', parseFormError(error));
        console.error('Erro ao atualizar o equipamento:', error);
      }
    },
    async stopEditing({ commit }) {
      const value = { open: false,  };
      commit("SET_FORM", value);
    }
      
  },
  mutations: {
    SET_EQUIPAMENTS(state, equipaments) {
      state.equipaments = equipaments;
      state.loading = false;
    },
    SET_QUERY(state, query) {
      state.query = query;
    },
    SET_PAGE(state, page) {
      state.page = page
    },
    SET_TOTAL(state, total) {
      state.total = total
    },
    SET_PER_PAGE(state, per_page) {
      state.perPage = per_page
    },
    SET_LAST_PAGE(state, last_page) {
      state.lastPage = last_page
    },

    SET_LOADING(state, value) {
      state.loading = value;
    },
    SET_FORM(state, { open, equipaments }) {
      state.form.open = open;
      state.form.equipaments = equipaments; 
    },
    SET_FORM_ERROR(state, error) {
      state.form.error = error;
    },
    resetState(state) {
      state.equipaments = [];
      state.loading = false;
      state.form = {
        open: false,
        equipaments: null, 
      };
    }
  }
}