export const EyeComplaints = {
    LOW_VISION: "Baixa visão",
    HEADACHE: "Dor de cabeça",
    RED_EYE: "Olho vermelho",
    DRY_EYE: "Olho seco",
    TEARING: "Lacrimejamento",
    OTHERS: "Outros",
};

export default EyeComplaints;

export const EyeComplaintsOptions = Object.values(EyeComplaints).map(value => ({
    value: value,
    label: value,
}));
