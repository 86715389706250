import api from "./axios";

export default {
    async getHealthcareOperators(page = 1, per_page = 10) {
        const response = await api.get(`/healthcareOperators?page=${page}&per_page=${per_page}`)
        return response.data;
    },

    async exportAllOperators() {
        const response = await api.get('/healthcareOperators/exportAllOperators', { responseType: 'blob' });
        return response.data;
    },

    async findOperators(query, page = 1, per_page = 10) {
        const response = await api.get(`/healthcareOperators`, {
            params: {
                query: query,
                page: page,
                per_page: per_page
            }
        });
        return response.data;
    },

    async getOptions(){
        const response = await api.get("/health/options");
        return response.data;
    },
    async addHealthcareOperator(healthcareOperator) {
        const response = await api.post("/healthcareOperators", healthcareOperator)

        return response.data;
    },
    async updateHealthcareOperator(healthcareOperator) {
        const response = await api.put(`/healthcareOperators/${healthcareOperator.id}`, healthcareOperator)

        return response.data;
    },
}