<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card Header -->
          <div class="pb-0 card-header">
            <div class="d-lg-flex justify-content-between align-items-center">
              <h5 class="mb-0">Leads</h5>
              <div class="d-flex align-items-center mt-4 mt-lg-0 w-100 w-md-50 w-lg-40">
                <input
                  type="text"
                  class="form-control me-2"
                  v-model="searchQuery"
                  @input="searchLeads"
                  placeholder="Buscar..."
                />
                <a class="mb-0 btn bg-gradient-success btn-sm me-2" @click="handleFileInput">
                  Importar Excel
                </a>
                <input type="file" ref="fileInput" style="display: none;" @change="handleFileUpload" />
                <a class="mb-0 btn bg-gradient-success btn-sm me-2" @click="exportExcel">
                  Gerar Excel
                </a>
                <a @click="startAdding" class="mb-0 btn bg-gradient-success btn-sm">
                  Novo Lead
                </a>
              </div>
            </div>
          </div>

          <!-- Filtros -->
          <div class="container-fluid mt-2">
            <div class="row g-2 align-items-end justify-content-end">
              <div class="col-12 col-md-2">
                <label for="leadNames" class="form-label w-100">Filtrar por Nome:</label>
                <select v-model="selectedLeadNames" id="leadNames" class="form-control">
                  <option v-for="lead in filteredLeadNames" :key="lead" :value="lead">{{ lead }}</option>
                </select>
              </div>
              <div class="col-12 col-md-2">
                <label for="leadOrigins" class="form-label w-100">Filtrar por Origem:</label>
                <select v-model="selectedLeadOrigins" id="leadOrigins" class="form-control">
                  <option v-for="source in filteredOrigins" :key="source" :value="source">{{ source }}</option>
                </select>
              </div>
              <div class="col-12 col-md-2">
                <label for="leadFunnels" class="form-label w-100">Filtrar por Funil:</label>
                <select v-model="selectedLeadFunnels" id="leadFunnels" class="form-control">
                  <option v-for="funnel in filteredFunnels" :key="funnel" :value="funnel">{{ funnel }}</option>
                </select>
              </div>
              <div class="col-12 col-md-2">
                <label for="leadRegions" class="form-label w-100">Filtrar por Região:</label>
                <select v-model="selectedLeadRegions" id="leadRegions" class="form-control">
                  <option v-for="region in filteredRegions" :key="region" :value="region">{{ region }}</option>
                </select>
              </div>
              <div class="col-12 col-md-4 position-relative">
                <label class="form-label w-100 text-center filter-title">Filtrar por Período: </label>
                <div class="d-flex gap-2 align-items-center">
                  <div class="w-50">
                    <input
                      type="date"
                      v-model="dateRange.start"
                      class="form-control"
                      :max="dateRange.end"
                    />
                  </div>
                  <div class="w-50">
                    <input
                      type="date"
                      v-model="dateRange.end"
                      class="form-control"
                      :min="dateRange.start"
                    />
                  </div>
                </div>

              </div>
              <div class="col-12 col-md-auto mb-0">
                <button @click="clearFilters" class="btn bg-gradient-success w-100 mb-0">Limpar Filtros</button>
              </div>
            </div>
          </div>

          <!-- Loading Spinner -->
          <div class="spinner-border" role="status" v-if="loading">
            <span class="sr-only">Carregando...</span>
          </div>

          <!-- Card Body -->
          <div class="px-0 pb-0 card-body" v-else>
            <div class="table-responsive">
              <table id="leads-list" ref="leadsList" class="table table-flush">
                <thead class="thead-light">
                  <tr>
                    <!-- Cabeçalho para ordenar por nome -->
                    <th @click="sortByName" class="click">
                      <strong>
                        Nome&nbsp;
                        <span>
                          <i :class="{
                              'fa fa-chevron-up': sortNameOrder === 1,
                              'fa fa-chevron-down': sortNameOrder === 2,
                              'fa fa-bars': sortNameOrder === 0
                            }"></i>
                        </span>
                      </strong>
                    </th>
                    <th @click="sortByOrigin" class="click">
                      <strong>
                        Origem&nbsp;
                        <span>
                          <i :class="{
                              'fa fa-chevron-up': sortOriginOrder === 1,
                              'fa fa-chevron-down': sortOriginOrder === 2,
                              'fa fa-bars': sortOriginOrder === 0
                            }"></i>
                      </span>
                      </strong>
                    </th>
                    <th @click="sortByFunnel" class="click">
                      <strong>
                        Funil&nbsp;
                        <span>
                          <i :class="{
                              'fa fa-chevron-up': sortFunnelOrder === 1,
                              'fa fa-chevron-down': sortFunnelOrder === 2,
                              'fa fa-bars': sortFunnelOrder === 0
                            }"></i>
                        </span>
                      </strong>
                    </th>
                    <th><strong>Região</strong></th>
                    <th @click="sortByDate" class="click">
                      <strong>
                        Data de Cadastro&nbsp;
                        <span>
                          <i :class="{
                              'fa fa-chevron-up': sortDateOrder === 1,
                              'fa fa-chevron-down': sortDateOrder === 2,
                              'fa fa-bars': sortDateOrder === 0
                            }"></i>
                        </span>
                      </strong>
                    </th>
                  </tr>
                </thead>
                <tbody class="text-sm">
                  <lead-tile v-for="(lead, index) in sortedLeads" :lead="lead" :key="index" />
                </tbody>
              </table>
            </div>
          </div>

          <pagination 
            :current="page"
            :perPage="perPage"
            :onChangePage="changeToPage"
            :totalPages="lastPage"
            :totalValues="total"
          /> 
        </div>
      </div>
    </div>
    <lead-modal />
  </div>
</template>

<script>
import LeadTile from "./components/LeadTile.vue";
import LeadModal from "./components/LeadModal.vue";
import { mapState } from 'vuex';
import Pagination from "../components/Pagination.vue";
import list_mixin from "../mixins/list_mixin";

export default {
  name: "Leads",
  components: {
      LeadTile,
      LeadModal,
      Pagination
  },

  mixins: [list_mixin('leads')],

  data() {
      return {
          searchQuery: "",
          sortNameOrder: 0,
          sortOriginOrder: 0,
          sortFunnelOrder: 0,
          sortDateOrder: 0,
          selectedLeadNames: [],
          selectedLeadOrigins: [],
          selectedLeadFunnels: [],
          selectedLeadRegions: [],
          dateRange: {
            start: null,
            end: null
          },
          loading: false
      };
  },

  computed: {
      ...mapState('leads', {
        leads: state => state.leads,
      }),

      filteredLeadNames() {
        if (!Array.isArray(this.leads)) return [];
        return [...new Set(this.leads.map(lead => lead.name).filter(name => name != null))];
      },

      filteredOrigins() {
        if (!Array.isArray(this.leads)) return [];
        return [...new Set(this.leads.map(lead => lead.source).filter(source => source != null))];
      },

      filteredFunnels() {
        if (!Array.isArray(this.leads)) return [];
        return [...new Set(this.leads.map(lead => lead.funnel_stage).filter(funnel => funnel != null))];
      },

      filteredRegions() {
        if (!Array.isArray(this.leads)) return [];
        return [...new Set(this.leads.map(lead => lead.city).filter(state => state != null))];
      },

      filteredDates() {
        if (!Array.isArray(this.leads)) return [];
        return [...new Set(this.leads.map(lead => lead.created_at?.split(' ')[0]).filter(date => date != null))];
      },

      filteredLeads() {
        if (!Array.isArray(this.leads)) return [];
        return this.leads.filter(lead => {
          const nameMatch = this.selectedLeadNames.length === 0 || this.selectedLeadNames.includes(lead.name);
          const originMatch = this.selectedLeadOrigins.length === 0 || this.selectedLeadOrigins.includes(lead.source);
          const funnelMatch = this.selectedLeadFunnels.length === 0 || this.selectedLeadFunnels.includes(lead.funnel_stage);
          const regionMatch = this.selectedLeadRegions.length === 0 || this.selectedLeadRegions.includes(lead.city);

          let dateMatch = true;
          if (this.dateRange.start || this.dateRange.end) {
            const leadDate = new Date(lead.created_at);
            if (this.dateRange.start) {
              dateMatch = dateMatch && leadDate >= new Date(this.dateRange.start);
            }
            if (this.dateRange.end) {
              dateMatch = dateMatch && leadDate <= new Date(this.dateRange.end);
            }
          }

          return (
            nameMatch &&
            originMatch &&
            funnelMatch &&
            regionMatch &&
            dateMatch &&
            lead.name &&
            lead.name.toLowerCase().includes(this.searchQuery.toLowerCase())
          );
        });
      },

      sortedLeads() {
          let sorted = [...this.filteredLeads];

          if (this.sortNameOrder !== 0) {
              sorted.sort((a, b) => (a.name || '').localeCompare(b.name || ''));
              if (this.sortNameOrder === 2) sorted.reverse();
          }

          if (this.sortOriginOrder !== 0) {
              sorted.sort((a, b) => {
                  const originA = a.origin || '';
                  const originB = b.origin || '';
                  return originA.localeCompare(originB);
              });
              if (this.sortOriginOrder === 2) sorted.reverse();
          }

          if (this.sortFunnelOrder !== 0) {
              sorted.sort((a, b) => {
                  const funnelA = a.funnel || '';
                  const funnelB = b.funnel || '';
                  return funnelA.localeCompare(funnelB);
              });
              if (this.sortFunnelOrder === 2) sorted.reverse();
          }

          if (this.sortDateOrder !== 0) {
              sorted.sort((a, b) => {
                  const dateA = new Date(a.created_at || '');
                  const dateB = new Date(b.created_at || '');
                  return dateA - dateB;
              });
              if (this.sortDateOrder === 2) sorted.reverse();
          }

          return sorted;
      }
  },
  methods: {
    handleFileUpload(event) {
        this.file = event.target.files[0];
        this.error = null;
        this.importExcel(this.file);
      },

      handleFileInput() {
        this.$refs.fileInput.click();
      },

      async importExcel(file) {
        if (!file) {
          this.error = "Por favor, selecione um arquivo para importação.";
          return;
        }

        this.loading = true;
        const formData = new FormData();
        formData.append('file', file, file.name);
        try {
          await this.$store.dispatch('leads/importLeads', formData);
          this.loading = false;
          this.$refs.fileInput.value = "";
        } catch (error) {
          this.loading = false;
          this.error = "Erro ao importar o arquivo. Tente novamente.";
          console.error("Erro ao importar o arquivo:", error);
        }
      },
      exportExcel() {
        this.$store.dispatch('leads/exportAllLeads');
      },

      startAdding() {
          this.$store.dispatch('leads/startAdding');
      },

      sortByName() {
          this.sortNameOrder = (this.sortNameOrder + 1) % 3;
          this.sortOriginOrder = 0;
          this.sortFunnelOrder = 0;
      },

      sortByOrigin() {
          this.sortOriginOrder = (this.sortOriginOrder + 1) % 3;
          this.sortNameOrder = 0;
          this.sortFunnelOrder = 0;
      },

      sortByFunnel() {
          this.sortFunnelOrder = (this.sortFunnelOrder + 1) % 3;
          this.sortNameOrder = 0;
          this.sortOriginOrder = 0;
      },

      sortByDate() {
          this.sortDateOrder = (this.sortDateOrder + 1) % 3;
          this.sortNameOrder = 0;
          this.sortOriginOrder = 0;
          this.sortFunnelOrder = 0;
      },

      formatDate(dateString) {
        if (!dateString) return '';
        const date = new Date(dateString);
        return date.toLocaleDateString('pt-BR');
      },

      clearFilters() {
        this.selectedLeadNames = [];
        this.selectedLeadOrigins = [];
        this.selectedLeadFunnels = [];
        this.selectedLeadRegions = [];
        this.dateRange.start = null;
        this.dateRange.end = null;
      },
  },

  async created() {
      try {
          await this.$store.dispatch("leads/getLeads");
      } catch (error) {
          console.error(error);
      }
  },
};
</script>

<style scoped>
td {
  padding: 12px 20px;
}

thead th {
  font-weight: bold;
}

.table tbody tr:nth-child(odd) {
  background-color: #f9f9f9;
}

.table tbody tr:nth-child(even) {
  background-color: #e0f7fa;
}

.click {
  cursor: pointer;
}
</style>
