<template>
  <div 
    id="triage_form_modal"
    class="modal fade"
    tabindex="-1"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modal_demo_label">{{ title }}</h5>
        </div>
        <div class="modal-body">
          <Form
            id="triageForm"
            ref="triageForm"
            role="form"
            class="text-start"
            @submit="handleSubmit"
          >
            <div class="mb-3">
              <label class="form-label">Histórico Pessoal</label>
              <div v-for="(item, index) in state.personal_history" :key="index" class="d-flex mb-2">
                <soft-select 
                  :id="`personal_history_${index}`"
                  :name="`personal_history_${index}`"
                  v-model="state.personal_history[index]"
                  :options="PersonalHistoriesOptions"
                  placeholder="Selecione um histórico pessoal"
                  class="flex-grow-1 me-2"
                />
                <button 
                  v-if="index === state.personal_history.length - 1" 
                  type="button" 
                  class="btn btn-success"
                  @click="addPersonalHistoryField"
                >
                  +
                </button>
                <button 
                  v-if="state.personal_history.length > 1 && index === state.personal_history.length - 1" 
                  type="button" 
                  class="btn btn-secondary ms-2"
                  @click="removePersonalHistoryField"
                >
                  -
                </button>
              </div>
            </div>
            <hr>
            <soft-switch-model 
              id="smoking"
              name="smoking"
              v-model="state.smoking"
            >
              Tabagismo
            </soft-switch-model>

            <soft-switch-model 
              id="allergy"
              name="allergy"
              v-model="state.allergy"
            >
              Alergia
            </soft-switch-model>

            <div v-if="state.allergy">
              <label for="allergy_description">Descreva a alergia</label>
              <textarea
                id="allergy_description"
                name="allergy_description"
                v-model="state.allergy_description"
                class="form-control"
                placeholder="Descreva a alergia"
              ></textarea>
            </div>
            
            <soft-switch-model 
              id="wear_glasses"
              name="wear_glasses"
              v-model="state.wear_glasses"
            >
              Usa óculos
            </soft-switch-model>
            
            <soft-switch-model 
              id="wear_contact_lenses"
              name="wear_contact_lenses"
              v-model="state.wear_contact_lenses"
            >
              Usa lentes de contato
            </soft-switch-model>

            <label for="screen_time">Tempo de tela por dia</label>
            <soft-select 
              id="screen_time"
              name="screen_time"
              v-model="state.screen_time"
              :options="ScreenTimeOptions"
              placeholder="Tempo de tela por dia"
            />

            <hr>

            <div class="mb-3">
              <label class="form-label">Cirurgias Oculares Prévias</label>
              <div v-for="(item, index) in state.previous_eye_surgeries" :key="index" class="d-flex mb-2">
                <soft-select 
                  :id="`previous_eye_surgeries_${index}`"
                  :name="`previous_eye_surgeries_${index}`"
                  v-model="state.previous_eye_surgeries[index]"
                  :options="EyeSurgeryPreviousOptions"
                  placeholder="Selecione cirurgia ocular prévia"
                  class="flex-grow-1 me-2"
                />
                <button 
                  v-if="index === state.previous_eye_surgeries.length - 1" 
                  type="button" 
                  class="btn btn-success"
                  @click="addEyeSurgeryField"
                >
                  +
                </button>
                <button 
                  v-if="state.previous_eye_surgeries.length > 1 && index === state.previous_eye_surgeries.length - 1" 
                  type="button" 
                  class="btn btn-secondary ms-2"
                  @click="removeEyeSurgeryField"
                >
                  -
                </button>
              </div>
            </div>

            <div class="mb-3">
              <label class="form-label">Doenças Oculares Prévias</label>
              <div v-for="(item, index) in state.previous_eye_diseases" :key="index" class="d-flex mb-2">
                <soft-select 
                  :id="`previous_eye_diseases_${index}`"
                  :name="`previous_eye_diseases_${index}`"
                  v-model="state.previous_eye_diseases[index]"
                  :options="EyeDiseasesPreviousOptions"
                  placeholder="Selecione doença ocular prévia"
                  class="flex-grow-1 me-2"
                />
                <button 
                  v-if="index === state.previous_eye_diseases.length - 1" 
                  type="button" 
                  class="btn btn-success"
                  @click="addEyeDiseaseField"
                >
                  +
                </button>
                <button 
                  v-if="state.previous_eye_diseases.length > 1 && index === state.previous_eye_diseases.length - 1" 
                  type="button" 
                  class="btn btn-secondary ms-2"
                  @click="removeEyeDiseaseField"
                >
                  -
                </button>
              </div>
            </div>
            
            <div class="mb-3">
              <label class="form-label">Queixas oftalmológicas</label>
              <div v-for="(item, index) in state.eye_complaints" :key="index" class="d-flex mb-2">
                <soft-select 
                  :id="`eye_complaints_${index}`"
                  :name="`eye_complaints_${index}`"
                  v-model="state.eye_complaints[index]"
                  :options="EyeComplaintsOptions"
                  placeholder="Selecione queixas oftalmológicas"
                  class="flex-grow-1 me-2"
                />
                <button 
                  v-if="index === state.eye_complaints.length - 1" 
                  type="button" 
                  class="btn btn-success"
                  @click="addEyeComplaintField"
                >
                  +
                </button>
                <button 
                  v-if="state.eye_complaints.length > 1 && index === state.eye_complaints.length - 1" 
                  type="button" 
                  class="btn btn-secondary ms-2"
                  @click="removeEyeComplaintField"
                >
                  -
                </button>
              </div>
            </div>

          </Form>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            @click="onClose"
          >Cancelar</button>
          <button
            type="submit"
            form="triageForm"
            class="btn btn-success"
            :disabled="loading ? true : false"
          >
            <span
              v-if="loading"
              class="spinner-border spinner-border-sm"
            ></span>
            <span v-else>Salvar</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bootstrap from "bootstrap/dist/js/bootstrap";
import { mapActions, mapState } from "vuex/dist/vuex.cjs.js";
import SoftSelect from "../../components/SoftSelect.vue";
import SoftSwitchModel from "../../components/SoftSwitchModel.vue";
import formModalMixin from "../../mixins/form-modal-mixin";
import { ScreenTimeOptions } from "../../constants/ScreenTime";
import { PersonalHistoriesOptions } from "../../constants/PersonalHistories";
import { EyeSurgeryPreviousOptions } from "../../constants/EyeSurgeryPrevious";
import { EyeDiseasesPreviousOptions } from "../../constants/EyeDiseasesPrevious";
import { EyeComplaintsOptions } from "../../constants/EyeComplaints";
import { Form } from "vee-validate";

function getState(triage = null) {
  return {
    id: triage?.id,
    personal_history: triage?.personal_history?.length ? triage.personal_history : [''],
    previous_eye_surgeries: triage?.previous_eye_surgeries?.length ? triage.previous_eye_surgeries : [''],
    previous_eye_diseases: triage?.previous_eye_diseases?.length ? triage.previous_eye_diseases : [''],
    eye_complaints: triage?.eye_complaints?.length ? triage.eye_complaints : [''],
    smoking: triage?.smoking ?? false,
    wear_glasses: triage?.wear_glasses ?? false,
    wear_contact_lenses: triage?.wear_contact_lenses ?? false,
    screen_time: triage?.screen_time ?? '',
    allergy: triage?.allergy ?? false,
    allergy_description: triage?.allergy_description ?? '',
  }
}

export default {
  name: "TriageFormModal",
  mixins: [formModalMixin('triages')],
  components: {
    SoftSelect,
    SoftSwitchModel,
    Form
  },
  mounted() {
    this.triage_modal = new bootstrap.Modal("#triage_form_modal" , {
      backdrop: 'static'
    });
  },
  data() {
    return {
      loading: false,
      state: getState(),
      ScreenTimeOptions,
      PersonalHistoriesOptions,
      EyeSurgeryPreviousOptions,
      EyeDiseasesPreviousOptions,
      EyeComplaintsOptions
    }
  },
  computed: {
    ...mapState('triages', {
      open: state => state.form.open,
      triage: state => state.form.triage,
      patientId: state => state.form.patientId,
      title: state => state.form.triage?.id ? 'Editar Triagem' : 'Nova Triagem'
    }),
  },
  async created() {
    try {
      await this.$store.dispatch("personalHistory/getOptions");
      await this.$store.dispatch("eyeSurgeries/getOptions");
      await this.$store.dispatch("eyeDiseases/getOptions");
      await this.$store.dispatch("eyeComplaints/getOptions");
    } catch (error) {
      console.error(error);
    }
  },
  watch: {
    open (newOpen) {
      this.resetForm();
      if (newOpen) this.openTriageModal();
      else this.closeTriageModal();
    },
  },
  methods: {
    ...mapActions('triages', {
      onClose: "stopEditing"
    }),
    async handleSubmit() {
      this.loading = true;
      try {
        const filteredPersonalHistory = this.state.personal_history.filter(history => history);
        const filteredEyeSurgeries = this.state.previous_eye_surgeries.filter(surgery => surgery);
        const filteredEyeDiseases = this.state.previous_eye_diseases.filter(disease => disease);
        const filteredEyeComplaints = this.state.eye_complaints.filter(complaint => complaint)
        
        const data = {
          patientId: this.patientId,
          triage: {
            ...this.state,
            personal_history: filteredPersonalHistory,
            previous_eye_surgeries: filteredEyeSurgeries,
            previous_eye_diseases: filteredEyeDiseases,
            eye_complaints: filteredEyeComplaints,
            allergy: this.state.allergy,
            allergy_description: this.state.allergy_description
          }
        }

        if (this.triage) await this.$store.dispatch('triages/updateTriage', data);
        else await this.$store.dispatch('triages/addTriage', data);
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.error(error);
      }
    },
    stopEditing() {
      this.$store.dispatch('triages/stopEditing');
    },
    openTriageModal() {
      this.triage_modal.show();
    },
    closeTriageModal() {
      this.triage_modal.hide();
    },
    resetForm() {
      this.state = getState(this.triage);
      this.$refs.triageForm.resetForm();
    },
    addPersonalHistoryField() {
      if (this.state.personal_history[this.state.personal_history.length - 1]) {
        this.state.personal_history.push('');
      }
    },
    removePersonalHistoryField() {
      if (this.state.personal_history.length > 1) {
        this.state.personal_history.pop();
      }
    },
    addEyeSurgeryField() {
      if (this.state.previous_eye_surgeries[this.state.previous_eye_surgeries.length - 1]) {
        this.state.previous_eye_surgeries.push('');
      }
    },
    removeEyeSurgeryField() {
      if (this.state.previous_eye_surgeries.length > 1) {
        this.state.previous_eye_surgeries.pop();
      }
    },
    addEyeDiseaseField() {
      if (this.state.previous_eye_diseases[this.state.previous_eye_diseases.length - 1]) {
        this.state.previous_eye_diseases.push('');
      }
    },
    removeEyeDiseaseField() {
      if (this.state.previous_eye_diseases.length > 1) {
        this.state.previous_eye_diseases.pop();
      }
    },
    addEyeComplaintField() {
      if (this.state.eye_complaints[this.state.eye_complaints.length - 1]) {
        this.state.eye_complaints.push('');
      }
    },
    removeEyeComplaintField() {
      if (this.state.eye_complaints.length > 1) {
        this.state.eye_complaints.pop();
      }
    },
  },
}
</script>