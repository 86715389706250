<template>
  <tr @click="startEditing">
    <td>{{ userName }}</td>
    <td>{{ companyName }}</td>
    <td>{{ formattedDate }}</td>
  </tr>
</template>

<script>
import moment from 'moment';

export default {
  name: 'SupportTile',
  props: ['support'], 
  data() {
    return {
      userName: this.support.user_name,
      companyName: this.support.company_name,
      formattedDate: moment(this.support.data_atendimento).format("DD/MM/YYYY"),
    };
  },
  methods: {
  startEditing() {
    this.$store.dispatch('supports/startEditing', this.support);
    },
  },
};
</script>